.Centre {
  display: flex;
  justify-content: center;
}

.main_title {
  /* display: flex;
  justify-content: center; */
  /* height: 25px;
  width: 150px; */
}
.circle {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  height: 500px;
  width: 500px;
  background-color: #bbb;
  border-radius: 50%;
}
